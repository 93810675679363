import * as React from "react";
import Layout from "../components/layout";
import {
  launch,
  features,
  sublogos,
  logo,
  logos,
  appleLogo,
  maybeFixed,
  container,
  gallery,
} from "./index.module.css";
import "./index.css";

const IndexPage = () => {
  return (
    <Layout>
      <div className={container}>
        <p className={launch}>Your first photoalbum in a box</p>

        <div className={gallery}></div>

        <p className={features}>
          Because we believe the most enjoyable place for your memories is the
          real world
        </p>

        <div className={maybeFixed}>
          <div>
            <div className={logos}>
              <a
                href={
                  "https://apps.apple.com/nl/app/rckive-photos/id1579484115?itsct=apps_box_badge&itscg=30200"
                }
              >
                <img
                  src={
                    "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1651795200&h=cc2d7405f3cbbdb1a54e2416e1e94591"
                  }
                  alt="Download on the App Store"
                  className={appleLogo}
                />
              </a>
              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.rckive&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                }
              >
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  className={logo}
                />
              </a>
            </div>
            <div className={sublogos}>
              Download the app to get the full experience
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
