// extracted by mini-css-extract-plugin
export var container = "index-module--container--1TK4P";
export var launch = "index-module--launch--3uxvJ";
export var launchHeader = "index-module--launch-header--2w83H";
export var gallery = "index-module--gallery--dlY24";
export var features = "index-module--features--N601R";
export var sublogos = "index-module--sublogos--1jgJY";
export var logos = "index-module--logos--1fVMr";
export var appleLogo = "index-module--appleLogo--32zrt";
export var logo = "index-module--logo--3-YDv";
export var maybeFixed = "index-module--maybeFixed--LkPSG";